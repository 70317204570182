import React, { useCallback, useRef, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SegmentPickerBar from "./SegmentPickerBar/SegmentPickerBar"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import useClickOutHandler from "hooks/useClickOutHandler"
import useKeyListener from "hooks/useKeyListener"
import { useFetchAllSegments } from "resources/segment/segment/segmentQueries"
import styles from "./SegmentPicker.module.scss"
import { isNil, whereEq } from "ramda"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"
import lookalikesIcon from "images/lookalike.svg"

type ActivatedSegmentsIds = Array<Segment["id"]>

type Props = {
  className?: string
  errorMessage?: string
  hideLabel?: boolean
  disableActivated?: boolean
  activatedOptions?: ActivatedSegmentsIds
  label?: string
  disabled?: boolean
  enabledTypes?: SegmentType[]
} & (
  | {
      multiple: true
      clearable?: false
      value: Array<Segment["id"]> | null
      onChange: (newValue: Array<Segment["id"]>) => void | Promise<void>
    }
  | {
      multiple?: false
      clearable?: false
      value: Segment["id"] | null
      onChange: (newValue: Segment["id"]) => void | Promise<void>
    }
  | {
      multiple: true
      clearable: true
      value: Array<Segment["id"]> | null
      onChange: (newValue: Array<Segment["id"]> | null) => void | Promise<void>
    }
  | {
      multiple?: false
      clearable: true
      value: Segment["id"] | null
      onChange: (newValue: Segment["id"] | null) => void | Promise<void>
    }
)

const SegmentPicker = (props: Props) => {
  const {
    className,
    errorMessage,
    value,
    hideLabel,
    disableActivated,
    activatedOptions,
    multiple = false,
    label = "Segment",
    disabled = false,
    enabledTypes,
  } = props
  const [focused, setFocused] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onClose = useCallback(() => {
    setSearchTerm("")
    setFocused(false)
    inputRef.current?.blur()
  }, [])

  const { isOpen, open, close, ref, toggle } = useClickOutHandler({
    closeCallback: onClose,
    openCallback: () => setFocused(true),
    preventCloseIf: e =>
      [
        "SegmentPicker_input",
        "SegmentPicker_button",
        "SegmentPickerBar",
        "SegmentPicker_segments",
        "SegmentPicker_placeholder",
      ].some(className => (e.target as HTMLElement)?.className.includes?.(className)),
  })

  useKeyListener("Escape", close)

  const { data: segments, isLoading: areSegmentsLoading } = useFetchAllSegments()
  const allSegmentOptions =
    segments?.map(({ id, name, type }) => ({
      value: id,
      label: name,
      type,
      isActivated: !!activatedOptions?.includes(id),
    })) ?? []

  const selectedSegmentOption = allSegmentOptions?.find(whereEq({ value })) ?? null
  const selectedSegmentOptions = Array.isArray(value)
    ? allSegmentOptions?.filter(({ value: id }) => value.includes(id))
    : null

  // Can't do this filtering in the query select because I need ALL the segments in order to display
  // the selected segment chips
  const filteredSegmentOptions = searchTerm
    ? allSegmentOptions.filter(({ label }) =>
        label.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()),
      )
    : allSegmentOptions

  const selectedType = selectedSegmentOption?.type
  const hasValue = !isNil(value)

  return (
    <ErrorTippy disabled={!errorMessage} content={errorMessage}>
      <div className={className} data-testid="segment-picker">
        {!hideLabel && <label className={styles.label}>{label}</label>}
        <div className={styles.wrapper}>
          {!multiple && isLoading && (
            <LoadingIndicator className={styles.spinner} size="sm" fixedWidth />
          )}
          {!multiple && hasValue && !isLoading && (
            <Link
              className={classNames(styles.idChip, { [styles.disabled]: disabled })}
              to={getRoutePath(
                selectedType === "featured"
                  ? "segments.featured.detail"
                  : selectedType === "smart"
                  ? "segments.smart.detail"
                  : selectedType === "lookalike"
                  ? "segments.lookalike.detail"
                  : "segments.custom.detail",
                { id: value },
              )}
            >
              ID: {value}
            </Link>
          )}
          {!multiple && !isLoading && (
            <input
              ref={inputRef}
              autoComplete="off"
              className={classNames(styles.input, {
                [styles.error]: errorMessage,
                [styles.open]: focused || !selectedSegmentOption,
                [styles.hasValue]: hasValue,
              })}
              data-testid="segment-picker-input"
              placeholder={
                isLoading ? "" : selectedSegmentOption?.label ?? "Select segment or type to search"
              }
              type="text"
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              onFocus={open}
              disabled={disabled}
            />
          )}
          {/* Using un-destructured props to enable type inferrence for onChange */}
          {props.multiple && (
            <div
              className={classNames(styles.segments, { [styles.focused]: focused })}
              onClick={e => {
                if (
                  ["SegmentPicker_segments", "SegmentPicker_placeholder"].some(className =>
                    (e.target as HTMLElement)?.className.includes(className),
                  )
                )
                  open()
              }}
            >
              {selectedSegmentOptions?.map(item => (
                <div
                  key={item.value}
                  className={classNames(styles.segment, {
                    [styles.lookalike]: item.type === "lookalike",
                  })}
                >
                  {item.type === "lookalike" && (
                    <div className={styles.lookalikeIconWrapper}>
                      <img src={lookalikesIcon} className={styles.icon} alt="" />
                    </div>
                  )}
                  <span>{item.label}</span>
                  <IconButton
                    color="white"
                    icon="times"
                    variant="transparent"
                    className={styles.removeButton}
                    onClick={async () => {
                      setIsLoading(true)
                      try {
                        await props.onChange(
                          selectedSegmentOptions
                            ?.filter(o => o.value !== item.value)
                            .map(o => o.value) ?? [],
                        )
                      } catch {
                      } finally {
                        setIsLoading(false)
                      }
                    }}
                  />
                </div>
              ))}
              {areSegmentsLoading ? (
                <LoadingIndicator size="xs" fixedWidth />
              ) : (
                <input
                  ref={inputRef}
                  className={classNames(styles.placeholder, { [styles.hasValue]: searchTerm })}
                  placeholder="Select segment or type to search"
                  type="text"
                  value={searchTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(event.target.value)
                  }
                />
              )}
            </div>
          )}
          {props.clearable && hasValue && (
            <IconButton
              icon={["fas", "times"]}
              color="grey"
              variant="transparent"
              onClick={async () =>
                multiple ? await props.onChange(null) : await props.onChange(null)
              }
              className={styles.closeButton}
            />
          )}
          <button type="button" className={styles.caretButton} onClick={toggle} disabled={disabled}>
            <FontAwesomeIcon
              icon={["fas", focused ? "caret-up" : "caret-down"]}
              className={styles.icon}
            />
          </button>
          {isOpen && (
            <SegmentPickerBar
              ref={ref}
              loading={areSegmentsLoading}
              searchMode={!!searchTerm}
              options={filteredSegmentOptions}
              selectedOption={multiple ? null : selectedSegmentOption}
              onSelect={async newOption => {
                setIsLoading(true)
                close()
                try {
                  // Using un-destructured props to enable type inferrence for onChange
                  props.multiple
                    ? await props.onChange(
                        selectedSegmentOptions
                          ? [...selectedSegmentOptions, newOption].map(o => o.value)
                          : [newOption.value],
                      )
                    : await props.onChange(newOption.value)
                } catch {
                } finally {
                  setIsLoading(false)
                }
              }}
              disableActivated={disableActivated}
              enabledTypes={enabledTypes}
            />
          )}
        </div>
      </div>
    </ErrorTippy>
  )
}

export default SegmentPicker
