import FooterContainer from "app/components/FooterContainer/FooterContainer"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"
import IconButton from "../elements/IconButton/IconButton"
import styles from "./Page.module.scss"

export type PageProps = {
  title?: React.ReactNode
  headerContent?: React.ReactNode
  children: React.ReactNode
  className?: string
  headerClassName?: string
  contentClassName?: string
  backRouteFallback?: string | { pathname: string; state: Record<string, boolean> }
}
export default function Page({
  title,
  headerContent,
  children,
  className,
  headerClassName,
  contentClassName,
  backRouteFallback,
}: PageProps) {
  const history = useHistory()

  return (
    <div className={className}>
      {(title || headerContent) && (
        <header className={styles.header}>
          <div className={classnames(styles.headerInner, headerClassName)}>
            {title && (
              <h1 className={styles.title}>
                {backRouteFallback && (
                  <Link to={backRouteFallback}>
                    <IconButton
                      color="black"
                      onClick={evt => {
                        // @ts-ignore
                        if (history.location.state?.goBack) {
                          evt.preventDefault()
                          history.goBack()
                        }
                      }}
                      icon="chevron-left"
                      variant="transparent"
                      className={styles.backButton}
                    />
                  </Link>
                )}
                {title}
              </h1>
            )}
            {headerContent}
          </div>
        </header>
      )}
      <div className={styles.content}>
        <section className={classnames(contentClassName)}>{children}</section>
        <FooterContainer />
      </div>
    </div>
  )
}
