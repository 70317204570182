import React, { useState } from "react"
import classNames from "classnames"
import styles from "./NavBar.module.scss"
import { getRoutePath } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHasAccess } from "resources/user/currentUserQueries"
import UserMenuDropdown from "../UserMenuDropdown/UserMenuDropdown"
import NotificationsDropdown from "../NotificationsDropdown/NotificationsDropdown"
import clientDummyLogo from "images/client-dummy.png"
import HelpDropdown from "../HelpDropdown/HelpDropdown"
import { useNavScrollbar } from "./useNavScrollbar"
import { NavBarContext, NavGroup, NavItem, NavSubItem } from "./NavBarItems"
import { featureFlags } from "featureFlags"
import useClickOutHandler from "hooks/useClickOutHandler"

const clientLogoSrc = `${process.env.PUBLIC_URL}/img/client/logo.png`
const clientName =
  process.env.NODE_ENV === "production" ? "[[MEIRO_CLIENT]]" : process.env.REACT_APP_CLIENT_NAME

type NavBarProps = {
  reportingEnabled: boolean
  navProps: ReturnType<typeof useClickOutHandler> & { isNavCollapsible: boolean }
}

export default function NavBar({ reportingEnabled, navProps }: NavBarProps) {
  const hasAccess = useHasAccess()

  const {
    ref,
    isOpen: isNavExpanded,
    open: expandNav,
    close: collapseNav,
    toggle: toggleNavExpanded,
    isNavCollapsible,
  } = navProps

  // Scroll indicators
  const {
    mouseEnter,
    mouseLeave,
    showScrollbar,
    showTopOverlay,
    showBottomOverlay,
    scrollbarHeight,
    scrollbarTop,
    navRightEdge,
    navRef,
    navWrapperRef,
  } = useNavScrollbar()

  // Item collapse/expand
  const [expandedItem, setExpandedItem] = useState<string | null>(null)
  const [isActiveItemExpanded, setIsActiveItemExpanded] = useState(true)

  function resetNavState() {
    setExpandedItem(null)
    setIsActiveItemExpanded(true)
    if (isNavCollapsible) {
      collapseNav()
    }
  }

  return (
    <NavBarContext.Provider
      value={{
        expandedItem,
        isActiveItemExpanded,
        isNavExpanded,
        setExpandedItem,
        setIsActiveItemExpanded,
        expandNav,
        resetNavState,
      }}
    >
      <div
        className={classNames(styles.container, {
          [styles.collapsed]: !isNavExpanded,
          [styles.collapsible]: isNavCollapsible,
        })}
        ref={ref}
      >
        <div className={styles.innerContainer}>
          <div className={styles.top}>
            <UserMenuDropdown isCollapsed={!isNavExpanded} />
            {isNavExpanded && (
              <>
                <NotificationsDropdown />
                <HelpDropdown />
              </>
            )}
          </div>

          <div className={classNames(styles.topOverlay, { [styles.visible]: showTopOverlay })} />
          <div
            ref={navWrapperRef}
            className={styles.navWrapper}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            <div
              className={classNames(styles.scrollbar, { [styles.visible]: showScrollbar })}
              style={{
                top: scrollbarTop,
                left: navRightEdge - (isNavExpanded ? 5 : 2),
                height: scrollbarHeight,
              }}
            />
            <nav ref={navRef} className={styles.nav}>
              <NavItem id="home" name="Home" path={getRoutePath("home")} icon={"house"} />

              <NavItem
                id="profiles"
                name="Profiles"
                path={getRoutePath("profiles")}
                icon="users"
                hasAccess={hasAccess.customers.search}
              />

              <NavItem id="segments" name="Segments" path={getRoutePath("segments")} icon="filter">
                <NavSubItem name="Custom segments" path={getRoutePath("segments.custom")} />
                <NavSubItem name="Featured segments" path={getRoutePath("segments.featured")} />
                <NavSubItem name="Smart segments" path={getRoutePath("segments.smart")} />
                <NavSubItem name="Lookalike segments" path={getRoutePath("segments.lookalike")} />
                <NavSubItem
                  name="Tags"
                  path={getRoutePath("segments.tags")}
                  hasAccess={hasAccess.segments.viewTags}
                />
              </NavItem>

              <NavItem
                id="analytics"
                name="Analytics"
                path={getRoutePath("analytics")}
                icon="chart-column"
              >
                <NavSubItem
                  name="Diagnostic dashboard"
                  path={getRoutePath("analytics.dashboard")}
                  hasAccess={hasAccess.data.dashboard}
                />
                {featureFlags.FUNNELS && (
                  <NavSubItem name="Funnels" path={getRoutePath("analytics.funnels")} />
                )}
                <NavSubItem
                  name="Insights"
                  path={getRoutePath("analytics.insights")}
                  hasAccess={hasAccess.data.insights}
                />
                <NavSubItem
                  name="Reporting"
                  path={getRoutePath("analytics.reporting")}
                  hasAccess={hasAccess.reports}
                />
              </NavItem>

              <NavItem
                id="channels"
                name="Channels"
                path={getRoutePath("channels")}
                icon={"bullhorn"}
              >
                <NavGroup name="Web">
                  <NavSubItem name="Pop-up banners" path={getRoutePath("channels.popup-banners")} />
                  <NavSubItem
                    name="Native banners"
                    path={getRoutePath("channels.native-banners")}
                  />
                </NavGroup>
                <NavSubItem name="Mobile push" path={getRoutePath("channels.mobile-push")} />
                <NavSubItem name="SMS" path={getRoutePath("channels.sms")} comingSoon />
                <NavSubItem name="WhatsApp" path={getRoutePath("channels.whatsapp")} comingSoon />
                {featureFlags.EMAILS ? (
                  <NavGroup name="Emails">
                    <NavSubItem name="Campaigns" path={getRoutePath("channels.emails")} />
                    <NavSubItem
                      name="Templates"
                      path={getRoutePath("channels.emails.templates")}
                      hasAccess={hasAccess.emails.edit}
                    />
                  </NavGroup>
                ) : (
                  <NavSubItem name="Emails" path={getRoutePath("channels.emails")} />
                )}
                <NavSubItem name="Promo codes" path={getRoutePath("channels.promo-codes")} />
              </NavItem>

              <NavItem
                id="journeys"
                name="Journey canvas"
                path={getRoutePath("journeys")}
                icon="code-merge"
                comingSoon={!featureFlags.JOURNEYS}
              />

              <NavItem
                id="data"
                name="Data library"
                path={getRoutePath("data")}
                icon="folder-magnifying-glass"
                hasAccess={hasAccess.data.general}
              >
                <NavSubItem
                  name="Attributes"
                  path={getRoutePath("data.attributes")}
                  hasAccess={hasAccess.data.attributes}
                />
                <NavSubItem
                  name="Events"
                  path={getRoutePath("data.events")}
                  hasAccess={hasAccess.data.events}
                />
                <NavSubItem
                  name="Sources"
                  path={getRoutePath("data.sources")}
                  hasAccess={hasAccess.data.sourcesAndDestinations}
                />
                <NavSubItem
                  name="Destinations"
                  path={getRoutePath("data.destinations")}
                  hasAccess={hasAccess.data.sourcesAndDestinations}
                />
              </NavItem>

              {(hasAccess.administration.general || hasAccess.setup.general) && (
                <NavItem
                  id="administration"
                  name="Administration"
                  path={getRoutePath("administration")}
                  icon="wrench"
                >
                  <NavGroup name="Entities">
                    <NavSubItem
                      name="Sources"
                      path={getRoutePath("administration.sources")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Events"
                      path={getRoutePath("administration.events")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Identity stitching"
                      path={getRoutePath("administration.identity-stitching")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Attributes"
                      path={getRoutePath("administration.attributes")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Meta attributes"
                      path={getRoutePath("administration.metaAttributes")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Attribute labels"
                      path={getRoutePath("administration.labels")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                    <NavSubItem
                      name="Destinations"
                      path={getRoutePath("administration.destinations")}
                      hasAccess={hasAccess.setup.implementation}
                    />
                  </NavGroup>
                  <NavGroup name="Configuration">
                    <NavSubItem
                      name="Channels"
                      path={getRoutePath("administration.channels")}
                      hasAccess={hasAccess.setup.channels}
                    />
                    {reportingEnabled && (
                      <NavSubItem
                        name="Reporting"
                        path={getRoutePath("administration.reports")}
                        hasAccess={hasAccess.setup.reports}
                      />
                    )}
                    <NavSubItem
                      name="Insights"
                      path={getRoutePath("administration.insights")}
                      hasAccess={hasAccess.setup.insights}
                    />
                    <NavSubItem
                      name="Lookalikes"
                      path={getRoutePath("administration.lookalikeSettings")}
                      hasAccess={hasAccess.setup.lookalikeSettings}
                    />
                    {featureFlags.FUNNELS && (
                      <NavSubItem
                        name="Funnels"
                        path={getRoutePath("administration.funnel-groups")}
                        hasAccess={hasAccess.setup.funnelGroups}
                      />
                    )}
                    <NavSubItem
                      name="Settings"
                      path={getRoutePath("administration.settings")}
                      hasAccess={hasAccess.administration.globalSettings}
                    />
                  </NavGroup>
                  <NavGroup name="User setup">
                    <NavSubItem
                      name="Users activity"
                      path={getRoutePath("administration.users-activity")}
                      hasAccess={hasAccess.administration.usersActivity}
                    />
                    <NavSubItem
                      name="Users"
                      path={getRoutePath("administration.users")}
                      hasAccess={hasAccess.administration.users}
                    />
                    <NavSubItem
                      name="Roles"
                      path={getRoutePath("administration.roles")}
                      hasAccess={hasAccess.administration.users}
                    />
                  </NavGroup>
                </NavItem>
              )}
            </nav>
          </div>

          <div
            className={classNames(styles.bottomOverlay, { [styles.visible]: showBottomOverlay })}
          />

          {!isNavExpanded && (
            <>
              <NotificationsDropdown isAltPosition />
              <HelpDropdown isAltPosition />
            </>
          )}

          <div className={styles.collapseButtonWrapper}>
            <button className={styles.collapseButton} onClick={toggleNavExpanded}>
              <FontAwesomeIcon
                icon={["fas", "chevrons-left"]}
                className={styles.icon}
                flip={isNavExpanded ? undefined : "horizontal"}
              />
            </button>
          </div>

          <div className={styles.clientLogoWrapper}>
            <img
              onError={evt => {
                ;(evt.target as HTMLImageElement).src = clientDummyLogo
              }}
              src={clientLogoSrc}
              alt="Client logo"
              className={styles.clientLogo}
            />
            <div className={styles.clientName}>{clientName}</div>
          </div>
        </div>
      </div>
    </NavBarContext.Provider>
  )
}
