import { lazy, Suspense, useMemo } from "react"
import classNames from "classnames"
import styles from "./FunnelsDatePicker.module.scss"
import Button from "components/UI/elements/Button/Button"
import useClickOutHandler from "hooks/useClickOutHandler"
import { DateString } from "types/util"
import { parseISO, format, formatISO } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useEffect } from "react"
import { DATE_FMT } from "sharedConstants"
import { toLocalDate } from "utilities/date"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { DateRange, QuickOption } from "./FunnelsDataPicker.types"
import { DATE_OPTIONS } from "./FunnelsDataPicker.config"
const DatePicker = lazy(() => import("react-datepicker"))

type FunnelsDatePickerProps = {
  startDate: DateString
  endDate: DateString
  setStartDate: (date: DateString) => void
  setEndDate: (date: DateString) => void
  quickOption?: QuickOption
  setQuickOption: (quickOption?: QuickOption) => void
}

export default function FunnelsDatePicker({
  startDate: propStartDate,
  endDate: propEndDate,
  setStartDate: propSetStartDate,
  setEndDate: propSetEndDate,
  quickOption,
  setQuickOption,
}: FunnelsDatePickerProps) {
  const [dateRange, setDateRange] = useState<DateRange>({ start: new Date() })

  const { close, isOpen, ref, toggle } = useClickOutHandler({
    preventCloseIf: e =>
      [
        "react-datepicker__day--outside-month",
        "react-datepicker__year-option",
        "react-datepicker__month-option",
        "react-datepicker__navigation",
      ].some(className => (e.target as HTMLElement)?.className?.includes(className)),
    closeCallback: () => {
      setDateRange({ start: parseISO(propStartDate), end: parseISO(propEndDate) })
    },
  })

  const apply = () => {
    const { start, end } = dateRange

    if (!start || !end) return

    const formatedStart = formatISO(start, { representation: "date" })
    const formatedEnd = formatISO(end, { representation: "date" })

    propSetStartDate(formatedStart)
    propSetEndDate(formatedEnd)
    setQuickOption(undefined)

    close()
  }

  const handleClose = () => {
    setDateRange({ start: parseISO(propStartDate), end: parseISO(propEndDate) })
    close()
  }

  const onDateRangeChange = (date: [Date, Date]) => {
    const [start, end] = date
    setDateRange({ start, end })
  }

  const buttonText = useMemo(() => {
    if (quickOption) {
      return quickOption?.label
    }

    return `${format(toLocalDate(propStartDate), DATE_FMT.DATE)}–${format(
      toLocalDate(propEndDate),
      DATE_FMT.DATE,
    )}`
  }, [propEndDate, propStartDate, quickOption])

  useEffect(() => {
    setDateRange({ start: parseISO(propStartDate), end: parseISO(propEndDate) })

    if (!propStartDate || !propEndDate) {
      setQuickOption(DATE_OPTIONS.at(5))
    } else {
    }
  }, [propEndDate, propStartDate, setQuickOption])

  return (
    <div className={styles.container}>
      <div className={styles.label}>Date range</div>
      <button
        color="white"
        onClick={toggle}
        className={classNames(styles.button, { [styles.isOpen]: isOpen })}
      >
        {buttonText}
        <FontAwesomeIcon
          icon={["fas", "caret-down"]}
          className={styles.icon}
          flip={isOpen ? "vertical" : undefined}
        />
      </button>
      {isOpen && (
        <div className={styles.dropdown} ref={ref}>
          <div className={styles.dropdownHeader}>
            {DATE_OPTIONS.map(opt => {
              const isSelected =
                quickOption?.label === opt.label &&
                quickOption.startDate === propStartDate &&
                quickOption.endDate === propEndDate

              return (
                <Button
                  className={styles.option}
                  key={opt.label}
                  color={isSelected ? "primary" : "grey"}
                  size="xs"
                  variant={isSelected ? "solid" : "outlined"}
                  onClick={() => {
                    propSetStartDate(opt.startDate)
                    propSetEndDate(opt.endDate)
                    setQuickOption(opt)
                    close()
                  }}
                >
                  {opt.label}
                </Button>
              )
            })}
          </div>
          <div className={classNames("users-activity-datepicker", styles.calendarsWrapper)}>
            <Suspense fallback={<LoadingIndicator />}>
              <DatePicker
                selectsRange
                startDate={dateRange.start}
                endDate={dateRange.end}
                selected={dateRange.start}
                onChange={onDateRangeChange}
                calendarClassName="calendar-dropdown"
                dropdownMode="select"
                disabledKeyboardNavigation
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                inline
              />
            </Suspense>
            <div className={styles.dropdownFooter}>
              <Button color="grey" variant="outlined" onClick={handleClose}>
                cancel
              </Button>
              <Button onClick={apply}>apply</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
