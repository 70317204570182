import React from "react"
// import classNames from "classnames"
import styles from "./ChartCreate.module.scss"
import Page from "components/UI/Page/Page"
import { getRoutePath } from "routes"
import { useHistory, useLocation, useParams } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import { useCreateFunnelChart } from "resources/funnelChart/funnelChartQueries"
import { ChartCreatePayload, ChartType } from "resources/funnelChart/funnelChartTypes"
import FunnelChartForm from "../components/FunnelChartForm/FunnelChartForm"
import { getFunnelChartInitialValues, getLineChartInitialValues } from "../getInitialValues"
import LineChartForm from "../components/LineChartForm/LineChartForm"

export default function ChartCreate() {
  const { groupId } = useParams<{ groupId: string }>()
  const history = useHistory()
  const createMutation = useCreateFunnelChart()
  const location = useLocation()
  const typeFromURL = new URLSearchParams(location.search).get("type")
  let type: ChartType = "FUNNEL"
  if (typeFromURL === "line") {
    type = "LINE"
  }

  function createChart(data: ChartCreatePayload) {
    return createMutation.mutateAsync(
      { funnelGroupId: groupId, data },
      {
        onSuccess: ({ chart }) =>
          history.push(
            getRoutePath("analytics.funnels.group.chart-detail", { groupId, id: chart.id }),
          ),
      },
    )
  }

  return (
    <Page
      title="Create Chart"
      backRouteFallback={getRoutePath("analytics.funnels.group", { groupId })}
      headerContent={
        <div className={styles.buttons}>
          <Button
            variant="outlined"
            color="grey"
            onClick={() => history.push(getRoutePath("analytics.funnels.group", { groupId }))}
          >
            Cancel
          </Button>
          <Button type="submit" form="chartForm" loading={createMutation.isLoading}>
            Save
          </Button>
        </div>
      }
    >
      {type === "FUNNEL" ? (
        <FunnelChartForm
          onSubmit={createChart}
          initialValues={getFunnelChartInitialValues()}
          isEditable
        />
      ) : (
        <LineChartForm
          onSubmit={createChart}
          initialValues={getLineChartInitialValues()}
          isEditable
        />
      )}
    </Page>
  )
}
