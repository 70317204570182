import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { Controller, DeepPartial, useForm } from "react-hook-form"
import styles from "./LineChartForm.module.scss"
import { LineChartCreatePayload } from "resources/funnelChart/funnelChartTypes"
import SelectField from "components/UI/elements/SelectField"
import AttributePicker from "components/AttributePicker/AttributePicker"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { OPTION_GROUP_COLORS } from "sharedConstants"
import { useFetchAttributeById } from "resources/attribute/attributeQueries"
import { getCompoundAttributeSubAttributes } from "resources/attribute/compoundAttributeUtils"
import { SelectOption } from "types/util"
import { AttributeDataType } from "resources/attribute/attributeTypes"
import { maxLength, required } from "helpers/validators.helper"
import { whereEq } from "ramda"
import { getFunctionOptionsByDataType, isIncorrectFunctionForDataType } from "../funnelsUtils"

export type LineChartFormValues = LineChartCreatePayload

type LineChartFormProps = {
  onSubmit: (data: LineChartFormValues) => Promise<any>
  initialValues: DeepPartial<LineChartFormValues>
  isEditable: boolean
}

export default function LineChartForm({ initialValues, onSubmit, isEditable }: LineChartFormProps) {
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<LineChartFormValues>({
    defaultValues: initialValues,
  })

  async function submit(data: LineChartFormValues) {
    if (!data.description) {
      data.description = undefined
    }
    await onSubmit(data)
    reset(data)
  }

  const selectedAttributeId = watch("data.id")
  const { data: selectedAttribute } = useFetchAttributeById(selectedAttributeId ?? null)
  const dimensionOptions: Array<SelectOption<string> & { data_type: AttributeDataType }> =
    getCompoundAttributeSubAttributes(selectedAttribute?.data_type).map(
      ({ id, name, data_type }) => ({ value: id, label: name, data_type }),
    )
  const timeDimensionOptions = dimensionOptions.filter(({ data_type }) =>
    ["date", "datetime"].includes(data_type),
  )
  const dataDimensionOptions = dimensionOptions.filter(({ data_type }) =>
    ["string", "int", "float"].includes(data_type),
  )
  const selectedDataDimensionId = watch("data.data_dimension_id")
  const selectedDataType = dimensionOptions.find(
    whereEq({ value: selectedDataDimensionId }),
  )?.data_type

  const functionOptions = getFunctionOptionsByDataType(selectedDataType)

  return (
    <form id="chartForm" onSubmit={handleSubmit(submit)} className={styles.form}>
      <Paper className={styles.headPaper}>
        <TextInput
          {...register("name", { validate: { required } })}
          label="Name"
          error={errors.name?.message}
          disabled={!isEditable}
        />
        <TextInput
          {...register("description", { validate: { maxLength: maxLength(250) } })}
          error={errors.description?.message}
          label="Description (optional)"
          disabled={!isEditable}
        />
      </Paper>
      <Paper className={styles.attributePaper}>
        <div>
          <h3>Attribute</h3>
          <p className={styles.description}>
            Select a compound attribute with a <code>date</code>/<code>datetime</code> dimension for
            the <strong>time dimension</strong> and a <code>string</code>, <code>int</code>, or{" "}
            <code>float</code> dimension for the <strong>data dimension</strong>.
          </p>
        </div>
        <div className={styles.attribute}>
          <Controller
            control={control}
            name="data.id"
            rules={{ validate: { required } }}
            render={({ field }) => (
              <AttributePicker
                label="Attribute"
                value={field.value}
                onChange={id => {
                  field.onChange(id)
                  setValue(`data.timestamp_dimension_id`, "")
                  setValue(`data.data_dimension_id`, "")
                }}
                size="lg"
                allowedTypes={["compound"]}
                error={errors.data?.id?.message}
                readOnly={!isEditable}
              />
            )}
          />
          <div className={styles.dimensions}>
            <Controller
              control={control}
              name="data.timestamp_dimension_id"
              rules={{ validate: { required } }}
              render={({ field }) => (
                <SelectField
                  isSimpleValue
                  label="Time dimension"
                  input={field}
                  options={timeDimensionOptions}
                  noOptionsMessage="This attribute has no date or datetime dimensions."
                  disabled={!selectedAttributeId || !isEditable}
                  error={errors.data?.timestamp_dimension_id?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="data.data_dimension_id"
              rules={{ validate: { required } }}
              render={({ field: { value, onChange } }) => (
                <SelectField
                  isSimpleValue
                  label="Data dimension"
                  input={{
                    value,
                    onChange: (value: string) => {
                      onChange(value)

                      const dataType = dataDimensionOptions.find(whereEq({ value }))?.data_type
                      const functionValue = getValues("function")
                      if (!functionValue || !dataType) return

                      if (isIncorrectFunctionForDataType(functionValue, dataType))
                        setValue("function", "", { shouldDirty: true })
                    },
                  }}
                  options={dataDimensionOptions}
                  noOptionsMessage="This attribute has no string, int, or float dimensions."
                  disabled={!selectedAttributeId || !isEditable}
                  error={errors.data?.data_dimension_id?.message}
                />
              )}
            />
            <div className={styles.connectionLine} />
          </div>
        </div>
      </Paper>
      <Paper className={styles.paper}>
        <div>
          <h3>Aggregation function</h3>
          <p className={styles.description}>
            The available aggregation functions are <strong>Count</strong> and{" "}
            <strong>Unique counts</strong> for <code>string</code> data dimensions and{" "}
            <strong>Count</strong>, <strong>Average</strong>, and <strong>Sum</strong> for{" "}
            <code>int</code> and <code>float</code> data dimensions.
          </p>
        </div>
        <Controller
          control={control}
          rules={{
            validate: {
              required,
              validFunction(value) {
                if (selectedDataType === "string") {
                  return ["CARDINALITY", "COUNT"].includes(value)
                    ? undefined
                    : "Invalid function for string data dimension"
                }
                if (selectedDataType === "int" || selectedDataType === "float") {
                  return ["SUM", "AVERAGE", "COUNT"].includes(value)
                    ? undefined
                    : "Invalid function for int or float data dimension"
                }
              },
            },
          }}
          name="function"
          render={({ field }) => (
            <SelectField
              input={field}
              options={functionOptions}
              isSimpleValue
              error={errors.function?.message}
              disabled={!isEditable}
            />
          )}
        />
      </Paper>
      <Paper className={styles.paper}>
        <h3>Chart color</h3>
        <div className={styles.colorPickerWrapper}>
          <Controller
            control={control}
            name="frontend_settings.color"
            render={({ field }) => (
              <ColorRadioGroup
                input={field}
                meta={{ touched: true, error: "" }}
                colors={OPTION_GROUP_COLORS}
                disabled={!isEditable}
              />
            )}
          />
        </div>
      </Paper>
    </form>
  )
}
